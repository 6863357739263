<template>
  <a-spin :spinning="loading">
    <a-carousel>
      <div>
        <img
          src="@/assets/product/chips-banner-1.png"
          style="-webkit-user-drag: none"
          alt=""
        />
      </div>
    </a-carousel>

    <a-card :bordered="false" style="margin-left: 50px">
      <a-table
        :columns="$i18n.locale === 'enUS' ? columnsEn : columnsZh"
        :data-source="productList"
        :pagination="false"
        :row-key="(record) => record.id"
      >
        <a
          slot="name"
          slot-scope="text, record"
          @click="showDetailInfo(record)"
        >
          {{ text }}
        </a>

        <a slot="action" slot-scope="record" @click="showDetailInfo(record)">
          {{ $t('message')['product.chips.more'] }}
        </a>
      </a-table>
    </a-card>

    <a-drawer
      :title="$t('message')['product.chips.detail.title']"
      placement="right"
      :closable="false"
      :width="640"
      :visible="detailVisible"
      @close="onDetailClose"
    >
      <product-chips-detail :info="selectDetailInfo" />
    </a-drawer>
  </a-spin>
</template>

<script>
import { getLanguage } from '@/utils/languageUtils'
import { getNordicProductList } from '@/api/product'
import ProductChipsDetail from './ProductChipsDetail.vue'

export default {
  components: { ProductChipsDetail },
  name: 'ProductChipsList',
  data() {
    return {
      columnsZh: [
        {
          title: '模组型号',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '模组类型',
          dataIndex: 'type',
        },
        {
          title: '内置芯片',
          dataIndex: 'chip',
        },
        {
          title: '尺寸(mm)',
          dataIndex: 'size',
        },
        {
          title: '蓝牙版本',
          dataIndex: 'bleVersion',
        },
        {
          title: '休眠工作电流',
          dataIndex: 'sleepElectricity',
        },
        {
          title: 'RAM',
          dataIndex: 'RAM',
        },
        {
          title: 'flash',
          dataIndex: 'flash',
        },
        {
          title: '传输距离(m)',
          dataIndex: 'distance',
        },
        {
          title: '操作',
          dataIndex: '',
          scopedSlots: { customRender: 'action' },
        },
      ],
      columnsEn: [
        {
          title: 'Module model',
          dataIndex: 'nameEn',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Module type',
          dataIndex: 'typeEn',
        },
        {
          title: 'Inside SOC chip',
          dataIndex: 'chipEn',
        },
        {
          title: 'Size(mm)',
          dataIndex: 'sizeEn',
        },
        {
          title: 'BLE version',
          dataIndex: 'bleVersionEn',
        },
        {
          title: 'Sleep current',
          dataIndex: 'sleepElectricityEn',
        },
        {
          title: 'RAM',
          dataIndex: 'RAMEn',
        },
        {
          title: 'flash',
          dataIndex: 'flashEn',
        },
        {
          title: 'Transmission distance(m)',
          dataIndex: 'distanceEn',
        },
        {
          title: 'Operating',
          dataIndex: '',
          scopedSlots: { customRender: 'action' },
        },
      ],
      productList: [],
      loading: false,
      detailVisible: false,
      selectDetailInfo: {},
      params: {
        pageNum: 1,
        pageSize: 1000,
      },
    }
  },
  created() {
    this.getProductList()
  },
  methods: {
    // 获取产品列表
    getProductList() {
      this.loading = true
      getNordicProductList(this.params)
        .then((res) => {
          if (res.code === 200) {
            this.productList = res.rows
          } else if (res.code === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$message.error(err.message)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 显示详情信息
    showDetailInfo(record) {
      this.detailVisible = true
      this.selectDetailInfo = record
    },
    // 关闭详情
    onDetailClose() {
      this.detailVisible = false
    },
  },
}
</script>

<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 250px;
  line-height: 250px;
  background: #364d79;
  overflow: hidden;
}

.content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
}

.content-text {
  color: #333333;
  line-height: 30px;
  text-indent: 2em;
}
</style>
