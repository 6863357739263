<template>
  <div>
    <div v-if="info" class="product-img">
      <img :src="info.imageUrl" />
    </div>

    <a-divider />

    <div v-if="info" style="margin-bottpm: 15px">
      <a-row type="flex" class="info-item">
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.name'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.nameEn : info.name }}
        </a-col>
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.type'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.typeEn : info.type }}
        </a-col>
      </a-row>

      <a-row type="flex" class="info-item">
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.chip'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.chipEn : info.chip }}
        </a-col>
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.size'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.sizeEn : info.size }}
        </a-col>
      </a-row>

      <a-row type="flex" class="info-item">
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.bleVersion'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.bleVersionEn : info.bleVersion }}
        </a-col>
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.antenna'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.antennaEn : info.antenna }}
        </a-col>
      </a-row>

      <a-row type="flex" class="info-item">
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.storage'] }}:</span>
          {{
            $i18n.locale === 'enUS'
              ? info.RAMEn + '；' + info.flashEn
              : info.RAM + '；' + info.flash
          }}
        </a-col>
      </a-row>

      <a-row type="flex" class="info-item">
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.distance'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.distanceEn : info.distance }}
        </a-col>
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.f'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.fEn : info.f }}
        </a-col>
      </a-row>

      <a-row type="flex" class="info-item">
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.sleepElectricity'] }}:</span>
          {{
            $i18n.locale === 'enUS'
              ? info.sleepElectricityEn
              : info.sleepElectricity
          }}
        </a-col>
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.txAndRx'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.txAndRxEn : info.txAndRx }}
        </a-col>
      </a-row>

      <a-row type="flex" class="info-item">
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.temperatureMin'] }}:</span>
          {{
            $i18n.locale === 'enUS'
              ? info.temperatureMinEn
              : info.temperatureMin
          }}
        </a-col>
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.temperatureMax'] }}:</span>
          {{
            $i18n.locale === 'enUS'
              ? info.temperatureMaxEn
              : info.temperatureMax
          }}
        </a-col>
      </a-row>

      <a-row type="flex" class="info-item">
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.powerMax'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.powerMaxEn : info.powerMax }}
        </a-col>
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.sensitivity'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.sensitivityEn : info.sensitivity }}
        </a-col>
      </a-row>

      <a-row type="flex" class="info-item">
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.package'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.packageInfoEn : info.packageInfo }}
        </a-col>
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.pinCount'] }}:</span>
          {{ $i18n.locale === 'enUS' ? info.pinCountEn : info.pinCount }}
        </a-col>
      </a-row>

      <a-row type="flex" class="info-item">
        <a-col :span="12">
          <span>{{ $t('message')['product.dialogs.support'] }}:</span>
          {{
            $i18n.locale === 'enUS'
              ? info.supportEn
                ? 'yes'
                : 'no'
              : info.support
              ? '是'
              : '否'
          }}
        </a-col>
      </a-row>
    </div>

    <a-card
      v-if="info"
      :title="$t('message')['product.dialogs.feature']"
      style="margin-top: 15px"
    >
      <p v-for="item in featureList" :key="item">
        {{ item }}
      </p>
    </a-card>

    <a-card
      v-if="info"
      :title="$t('message')['product.dialogs.application']"
      style="margin-top: 15px"
    >
      <p>
        {{ $i18n.locale === 'enUS' ? info.applicationEn : info.application }}
      </p>
    </a-card>

    <a-card
      v-if="info && info.note"
      :title="$t('message')['product.dialogs.note']"
      style="margin-top: 15px"
    >
      <p>
        {{ $i18n.locale === 'enUS' ? info.noteEn : info.note }}
      </p>
    </a-card>

    <a-card
      v-if="info && info.dataResourceUrlEn && $i18n.locale === 'enUS'"
      style="margin-top: 15px"
    >
      <a target="_blank" :href="info.dataResourceUrlEn">
        {{ $t('message')['product.dialogs.download'] }}
      </a>
    </a-card>

    <a-card
      v-if="info && info.dataResourceUrl && $i18n.locale !== 'enUS'"
      style="margin-top: 15px"
    >
      <a target="_blank" :href="info.dataResourceUrl">
        {{ $t('message')['product.dialogs.download'] }}
      </a>
    </a-card>
  </div>
</template>

<script>
import { getLanguage } from '@/utils/languageUtils'
export default {
  name: 'ProductdialogsDetail',
  props: {
    info: {
      type: Object,
      default: null,
      require: true,
    },
  },
  computed: {
    featureList: {
      get() {
        if (getLanguage(this.$route) === 'enUS') {
          if (this.info && this.info.featureEn) {
            return this.info.featureEn.split('\n')
          } else {
            return []
          }
        } else {
          if (this.info && this.info.feature) {
            return this.info.feature.split('\n')
          } else {
            return []
          }
        }
      },
    },
  },
}
</script>

<style scoped>
.product-img {
  text-align: center;
}

.product-img img {
  border-radius: 5px;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
  width: 170px;
  height: 170px;
}

.info-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
