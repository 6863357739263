<template>
  <a-spin :spinning="loading">
    <a-carousel>
      <div>
        <img
          src="@/assets/product/banner-1.jpg"
          style="height: 100%; width: 100%; -webkit-user-drag: none"
          alt=""
        />
      </div>
    </a-carousel>

    <div class="editor-card">
      <a-card :bordered="false">
        <template slot="title">
          <a @click="() => $router.go(-1)">
            <h2>
              <a-icon
                type="arrow-left"
                style="font-size: 30px; color: #08c; margin-right: 20px"
              />

              {{ $i18n.locale === 'enUS' ? info.nameEn : info.name }}
            </h2>
          </a>
        </template>

        <template slot="extra">
          <a
            v-if="
              ($i18n.locale === 'enUS' && info.dataResourceUrlEn) ||
              ($i18n.locale !== 'enUS' && info.dataResourceUrl)
            "
            class="info-url"
            target="_blank"
            :href="
              $i18n.locale === 'enUS'
                ? info.dataResourceUrlEn
                : info.dataResourceUrl
            "
          >
            {{ $t('message')['product.data.dowload'] }}
          </a>
        </template>

        <div
          class="editor-content"
          v-html="$i18n.locale === 'enUS' ? info.richTextEn : info.richText"
        />
      </a-card>
    </div>
  </a-spin>
</template>

<script>
import { getLanguage } from '@/utils/languageUtils'
import { getProductDetail } from '@/api/product'
export default {
  name: 'ProductDetail',
  data() {
    return {
      loading: false,
      id: '',
      info: {},
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getProductInfo()
  },
  methods: {
    getProductInfo() {
      this.loading = true
      getProductDetail(this.id)
        .then((res) => {
          if (res.code === 200) {
            this.info = res.data
          } else if (res.code === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$message.error(err.message)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  /* height: 250px; */
  /* line-height: 250px; */
  background: #364d79;
  overflow: hidden;
}

.content {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
}

.content-text {
  color: #333333;
  line-height: 30px;
  text-indent: 2em;
}

.editor-card {
  margin-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
}

.editor-content {
  padding-left: 10%;
  padding-right: 10%;
}

.editor-content >>> img {
  max-width: 100% !important;
}

.editor-content >>> * {
  color: #333333;
  line-height: 30px;
  text-indent: 2em;
  font-size: 18px;
}

.info-url {
  line-height: 30px;
  text-indent: 2em;
  font-size: 18px;
}
</style>
